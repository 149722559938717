import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Layout, WizardFooter, Card, MainLoader, Heading, ActionButton, ExternalLink, FormFieldInputNew, useForm, Text } from "@netapp/bxp-design-system-react";
import styles from "./NoOrgDialog.module.scss";
import { setItemAnon } from "utils/localstorage";
import * as yup from 'yup';
import { SparksIcon } from "@netapp/bxp-design-system-react/icons/monochrome";

const orgFormSchema = yup.object().shape({
    orgName: yup
        .string()
        .matches(
            /^.{1,300}$/,
            "Please enter a name that contains 1 to 300 characters.",
          )
        .required("Organization name is required")
});

export default React.memo(() => {
    const {isReloading, isCreatingAccount, userCompany} = useSelector((state) => ({
                                                                            isReloading: state.app.isReloading,
                                                                            isCreatingAccount: state.tenancy?.creatingAccount,
                                                                            userCompany: state.tenancy?.user?.company
                                                                            }));
    const dispatch = useDispatch();
    const [isPending, setIsPending] = useState(true);
   
    const form = useForm({orgName: ''}, orgFormSchema);
    const { isValid, handleFormSubmit, formState } = form;

    useEffect(() => {
        if (userCompany) {
            form.handleFormChange(userCompany, {name: 'orgName'});
        }
        setIsPending(false);
    }, [userCompany]);

    const createOrganization = () => {
        setItemAnon({itemKey: `dontShowNewBlue`}, true)
        dispatch({
            type: 'TENANCYV4:CREATE-ORGANIZATION',
            payload: {
                organizationName: formState.orgName,
                resourceType: "organization",
                resourceClass: "hierarchy",
                version: "1.0",
                type : "application/vnd.netapp.bxp.resource",
            }
        });
    };

    if(isReloading) {
        return <MainLoader/>
    }

    return <>
        <Layout.Page className={styles.base}>
            <Layout.Content>
                <Layout.Container>
                    <Layout.Grid centerContent>
                        <Layout.GridItem lg={6} style={{paddingTop: '50px'}}>
                            <Card className={styles.cardBase}>
                                <div style={{position: 'relative'}}>
                                    <SparksIcon style={{position: 'absolute', right: '-215px', top: '-120px'}} width="400" height="300"/>
                                </div>
                                <Heading color="text-title" level="20">Welcome First,</Heading>
                                <br/><br/>
                                <Text bold level="16">Let's get started by creating an organization.</Text>
                                <br/>
                                <Text level="14">An organization is the top-level element in NetApp's identity platform. It enables you to add
                                and manage users, roles, permissions, licenses, and working environments.</Text>
                                <br/>
                                <Text level="14">If you need to join an existing organization or account, It's best to ask the account admin or
                                organization owner to add you to it.</Text>
                                <br/>
                                <ExternalLink variant="text" href="http://google.com" includeArrow={true}>Learn more about organizations</ExternalLink>
                                <br/><br/><br/>
                                <FormFieldInputNew
                                    name="orgName"
                                    label="Organization Name"
                                    disabled={isCreatingAccount}
                                    form={form}
                                    autoComplete="off"
                                    pending={isPending}
                                />
                                <br/>
                                <Layout.Container className={styles.infoSection}>
                                    <Text level="14">Associate a NetApp Support Site account with your organization to enable key workflows for Cloud Volumes ONTAP
                                    and proactive support.</Text>
                                    <br/>
                                    <ExternalLink variant="text" onClick={() => dispatch({
                                            type: "SUPPORT:ADD-NSS-OPEN-POPOVER"
                                            })} style={{cursor: 'pointer'}} includeArrow={false}>Associate a NetApp Support Site account</ExternalLink>
                                </Layout.Container>
                            </Card>
                        </Layout.GridItem>
                    </Layout.Grid>
                </Layout.Container>
            </Layout.Content>
            <WizardFooter>
                <ActionButton disabled={!isValid} onClick={handleFormSubmit(createOrganization)} isSubmitting={isCreatingAccount}>Create</ActionButton>
            </WizardFooter>
        </Layout.Page>
    </>
})