import _ from "lodash";
import querystring from "query-string";
import { matchPath } from "react-router-dom";
import { history } from "store";
import { handleApiAction } from "utils/actions";
import { apiClient, setForwarderAgent, setOccmAgent } from 'utils/clients';
import externals from "utils/externals";
import { setFullStoryUserValues, setGaAccount } from "utils/ga";
import { getItem, removeItem, setItem, isNewTenancyEnabled } from "utils/localstorage";
import { trackTiming } from "utils/mrTracker";

const fetchOrSetStoredItem = ({ storageItemKey, collection, collectionItemKey, preSetItem }) => {
    const storedItemId = preSetItem || getItem(storageItemKey);

    let selectedItem = null;

    if (storedItemId) {
        selectedItem = _.find(collection, { [collectionItemKey]: storedItemId });
    }

    if (!selectedItem && collection?.length > 0) {
        if (storageItemKey?.itemKey === "userScopeId") {
            let index = _.findIndex(collection, (ele) => ele?.resourceType === 'project')
            selectedItem = collection[index > -1 ? index : 0];
        } else {
            selectedItem = collection[0];
        }
        setItem(storageItemKey, selectedItem[collectionItemKey]);
    } else if (!selectedItem) {
        removeItem(storageItemKey);
        return null;
    } else if (preSetItem) {
        setItem(storageItemKey, selectedItem[collectionItemKey]);
    }

    return selectedItem[collectionItemKey];
};

const gcpRegionRegex = /(.+)?\/(.+)?\/(.+)?\/(.+)?/;
const maxLimit = "limit=10000"

const prepareHeirarchy = (root, scopes) => {
    const childScopes = []
    _.forEach(scopes, (scope) => {
        if (root.id === scope.parentId) {
            childScopes.push(scope);
        }
    })
    root.childScopes = childScopes;
    _.forEach(root?.childScopes, (scope) => {
        prepareHeirarchy(scope, _.difference(scopes, childScopes));
    })
}

const fetchUsers = async (selectedOrgId, payload, dispatch) => {
    try {
        const url = selectedOrgId ? `/organizations/${selectedOrgId}/users` : "/users"
        const { data: { items: users } } = await apiClient.get(`${externals.tenancyV4Backend}${url}?filter=auth0Id eq '${payload.userId}'`)
        dispatch({
            type: "TENANCYV4:FETCH-USER-SUCCESS",
            payload: {
                user: users[0]
            }
        });
        dispatch({
            type: "TENANCYV4:FETCH-ROLES"
        });
        return Promise.resolve(users)
    } catch (e) {
        dispatch({
            type: "TENANCYV4:FETCH-USER-FAILED",
            payload: e
        })
        dispatch({
            type: "NOTIFICATIONS:ERROR",
            payload: {
                isFatal: true,
                message: "Failed to fetch logged in user data",
                exception: e
            }
        })
        Promise.reject(e)
    }
            
}

const fetchOrgs = async (payload, dispatch) => {
    if (payload?.orgs) {
        return Promise.resolve(payload?.orgs)
    }
    try {
        const { data: {items: orgs} } = await apiClient.get(`${externals.tenancyV4Backend}/organizations?${maxLimit}`)
        return Promise.resolve(orgs)
    } catch (e) {
        dispatch({
            type: "TENANCYV4:FETCH-ORGS-FAILED",
            payload: e
        });
        Promise.reject(e)
    }
}

export default ({ dispatch, getState }) => next => async (action) => {
    const { type, payload } = action;
    const isTenancyV4 = isNewTenancyEnabled();
    
    if (type === "TENANCYV4:CREATE-ORGANIZATION") {
        const { organizationName, resourceType, resourceClass, version, type } = payload;

        return handleApiAction(action, () => {
            return apiClient.post(`${externals.tenancyV4Backend}/organizations`, {
                name: organizationName,
                resourceType,
                resourceClass,
                version,
                type,
            });
        }).then(() => {
            window.location.reload();
        }).catch(e => {
            const errMessage = _.get(e, 'response.data.invalidParams[0].reason') ?? "Unknown";
            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    message: "Failed to create organization",
                    exception: e,
                    group: "TENANCYV4:CREATE-ORGANIZATION",
                    moreInfo: errMessage
                }
            })
        })

    } else if (type === "TENANCYV4:FETCH-ROLES") {
        try {
            const { data: { items: roles } } = await apiClient.get(`${externals.tenancyV4Backend}/roles`)
            
            dispatch({
                type: "TENANCYV4:FETCH-ROLES-SUCCESS",
                payload: {
                    roles
                }
            });
        } catch (e) {
            dispatch({
                type: "TENANCYV4:FETCH-ROLES-FAILED",
                payload: e
            });
        }
    } else if (type === "TENANCYV4:FETCH-ORGS") {
        const state = getState();
        const tenancy = state.tenancy;
        const { pollAgents } = payload;

        if (tenancy.orgStatus !== "PENDING") {
            return;
        }

        const search = querystring.parse(history.location.search);
        let preSetOrgId = search.orgId;
        const preSetScopeId = search.scopeId;
        const preSetConnectorId = tenancy.localConnectorId || search.connectorId;

        if (search.orgId || search.scopeId || search.connectorId) {
            const searchString = querystring.stringify(_.omit(search.orgId, search.scopeId, search.connectorId));
            history.replace(history.location.pathname + (searchString ? `?${searchString}` : ''));
        }

        try {
            const localStorageItemKey = { itemKey: "userOrgId", userId: state.auth.userId };
            trackTiming({ category: "APP", name: "started orgs" });
            let orgs = await fetchOrgs(payload, dispatch)
            const isSetupMode = state.app.appState === "setup-wizard";
            let selectedOrgId = null;
            let forbidden = true;

            if (!_.isUndefined(orgs)) {
                if (tenancy.localAccountId) {
                    preSetOrgId = orgs.find((org) => org?.legacyId === tenancy.localAccountId)?.id;
                }
                selectedOrgId = fetchOrSetStoredItem({ storageItemKey: localStorageItemKey, collection: orgs, collectionItemKey: "id", preSetItem: preSetOrgId });
                forbidden = tenancy.localOrgId && preSetOrgId !== selectedOrgId;
                if (forbidden) {
                    selectedOrgId = null;
                }
                // Load users details only where there is org associated with it.
                if (orgs.length > 0 && payload.userId) {
                    await fetchUsers(selectedOrgId, payload, dispatch)
                }
    
                trackTiming({ category: "APP", name: "loaded orgs" });

                // TODO to be removed later
                orgs = orgs.map((org) => {
                    const legacyPublicTag = org.tags.find((tag) => !_.isUndefined(tag["internal:bxp:legacyId"]))
                    org.legacyId = legacyPublicTag ? legacyPublicTag["internal:bxp:legacyId"] : undefined;
                    return org
                })
            }
            

            dispatch({
                type: "TENANCYV4:FETCH-ORGS-SUCCESS",
                payload: {
                    selectedOrgId,
                    forbidden,
                    orgs: _.isUndefined(orgs) || _.isNull(orgs) ? [] : orgs
                }
            });

            if (selectedOrgId && !isSetupMode) {
                setGaAccount(selectedOrgId);
                setFullStoryUserValues({ "OrgId": selectedOrgId });
                dispatch({
                    type: "TENANCYV4:FETCH-SCOPES",
                    payload: {
                        preScopeId: preSetScopeId,
                        pollAgents
                    }
                });
            } else {
                dispatch({
                    type: "TENANCY:NO-AGENTS",
                });
            }
        } catch (e) {
            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    isFatal: true,
                    message: "Failed to load organization structure",
                    exception: e
                }
            })
        }
    } else if (type === "TENANCYV4:SET-ORG") {
        const { orgId } = payload;
        const state = getState();
        const userId = state.auth.userId;

        setItem({ itemKey: "userOrgId", userId }, orgId);
        const selectedOrg = _.find(state.tenancy.orgs, { id: orgId })
        setItem({ itemKey: "userAccountId", userId }, selectedOrg.legacyId);
        if (window.location.pathname.startsWith("/storage")) {
            window.location.replace("/");
        } else {
            window.location.reload();
        }
    } else if (type === "TENANCYV4:FETCH-SCOPES") {
        const { preScopeId, pollAgents } = payload;
        const state = getState();
        const selectedOrgId = state.tenancy.selectedOrgId;
        const userId = state.tenancy.user?.id;
        const localStorageItemKey = { itemKey: "userScopeId", userId: state.auth.userId };
        const search = querystring.parse(history.location.search);
        const preSetConnectorId = state.tenancy.localConnectorId || search.connectorId;
        dispatch({
            type: "TENANCYV4:FETCH-SCOPES-LOADING"
        });

        try {
            const apiCalls = [
                apiClient.get(`${externals.tenancyV4Backend}/organizations/${selectedOrgId}/resources?filter=resourceClass eq 'hierarchy' and resourceType ne 'organization'&${maxLimit}`),
                apiClient.get(`${externals.tenancyV4Backend}/permissions?filter=service_application eq 'bxp'&${maxLimit}`),
                apiClient.get(`${externals.tenancyV4Backend}/ui/organizations/${selectedOrgId}/users/${userId}/uiroles?${maxLimit}`),
            ]

            let [{ data: { items: scopes } }, { data: { items: permissions } }, { data: { items: scopeRoles } }] = await Promise.all(apiCalls);
            const selectedScopeId = scopes ? fetchOrSetStoredItem({ storageItemKey: localStorageItemKey, collection: scopes, collectionItemKey: "id", preSetItem: preScopeId }) : undefined;
            const selectedOrg = _.find(state.tenancy.orgs, { id: selectedOrgId })
            let permissionMap = {};
            for (const scope of scopeRoles) {
                permissionMap[scope.id] = permissions?.filter(p => p.tags
                    ?.filter(tag => Object.keys(tag)[0] === 'internal:bxp:roleId')
                    ?.find(tag => scope?.roles?.length > 0 && tag['internal:bxp:roleId'] === scope.roles[0].id))

                if (permissionMap[scope.id].length === 0) {
                    permissionMap[scope.id].push(permissions?.find(p => p.rule === `bxp:${scope.resourceType}-read`))
                }
            }

            // TODO to be removed when the legacyId is a resource variable
            scopes = scopes ? scopes.map((scope) => {
                if (scope.resourceType === "project") {
                    const legacyPublicTag = scope.tags.find((tag) => !_.isUndefined(tag["internal:bxp:legacyId"]))
                    if(!_.isUndefined(legacyPublicTag)) {
                        scope.legacyId = legacyPublicTag["internal:bxp:legacyId"];
                    }
                }
                scope['rules'] = permissionMap[scope.id]?.filter(permission => permission.rule.startsWith('bxp:'))
                                                        .map(permission => permission.rule)
                scope['roles'] = _.merge(...scopeRoles?.filter(scopeRole => scope.id === scopeRole.id)
                                                        .map(scopeRole => scopeRole.roles))
                return scope
            }) : []
            selectedOrg['rules'] = permissionMap[selectedOrg.id]?.filter(permission => permission.rule.startsWith('bxp:'))
                                                                .map(permission => permission.rule)
            prepareHeirarchy(selectedOrg, scopes);

            dispatch({
                type: "TENANCYV4:UPDATE-SELECTED-ORG",
                payload: {
                    selectedOrg
                }
            });

            dispatch({
                type: "TENANCYV4:FETCH-SCOPES-SUCCESS",
                payload: {
                    scopes,
                    selectedScopeId
                }
            })
            dispatch({
                type: "TENANCYV4:SET-ROLES-PERMISSIONS",
                payload: {
                    permissions: permissions
                }
            })
            dispatch({
                type: "TENANCYV4:SET-PERMISSIONS",
                payload: {
                    scopeId: selectedScopeId
                }
            })
            dispatch({
                type: "TENANCY:LOAD-AGENTS",
                payload: {
                    preSetConnectorId,
                    first: true,
                    noPoll: !pollAgents
                }
            });
        } catch (e) {
            dispatch({
                type: "TENANCYV4:FETCH-SCOPES-FAILED",
                payload: e
            })

            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    isFatal: true,
                    message: "Failed to load folders and projects",
                    exception: e
                }
            })
        }
    } else if (type === "TENANCYV4:SET-SCOPE") {
        const state = getState();
        const { scopeId } = payload;
        const userId = state.auth.userId;

        setItem({ itemKey: "userScopeId", userId }, scopeId);

        const selectedScope = _.find(state.tenancy.scopes, { id: scopeId });
        if (selectedScope?.associatedAgents) {
            const selectedAccountId = state.tenancy.selectedAccountId;
            const selectedAgentId = (selectedScope?.associatedAgents?.length > 0) ? selectedScope?.associatedAgents[0].agentId : undefined;

            if (process.env.REACT_APP_IS_SAAS === "true") {
                setOccmAgent({ agentId: selectedAgentId, accountId: selectedAccountId });
            }
            setForwarderAgent({ agentId: selectedAgentId, accountId: selectedAccountId });
        }
        dispatch({
            type: "TENANCYV4:SET-PERMISSIONS",
            payload: {
                scopeId
            }
        })
    } else if (type === "TENANCYV4:LOAD-ADMIN-ORGS") {
        // This is used to load organizations with admin access for the logged in users.
        // This is used by the associate subscription workflow in digital wallet.
        const state = getState();
        const adminOrgs = state.tenancy.adminOrgs;
        const userId = state.tenancy.user?.id;
        if (!adminOrgs) {
            try {
            const { data: { items: permissions } } = await apiClient.get(`${externals.tenancyV4Backend}/permissions?filter=rule eq 'bxp:organization-modify'`);
            const adminPermissionId = permissions.length > 0 ? permissions[0].id : undefined;
                if (adminPermissionId) {
                    const { data: { items: resources } } = await apiClient.get(`${externals.tenancyV4Backend}/users/${userId}/permissions/${adminPermissionId}/resources?filter=resourceType eq 'organization'&${maxLimit}`);
                    const adminOrgs = resources.filter((resource) => resource.resourceType === 'organization');
                    dispatch({
                        type: "TENANCYV4:LOAD-ADMIN-ORGS-SUCCESS",
                        payload: {
                            adminOrgs
                        }
                    });
                } else {
                    dispatch({
                        type: "TENANCYV4:LOAD-ADMIN-ORGS-FAILED"
                    })
                }
            } catch (e) {
                dispatch({
                    type: "TENANCYV4:LOAD-ADMIN-ORGS-FAILED",
                    payload: e
                })
    
                dispatch({
                    type: "NOTIFICATIONS:ERROR",
                    payload: {
                        isFatal: true,
                        message: "Failed to load organizations with admin permissions",
                        exception: e
                    }
                })
            }

        }
    } else if (type === "TENANCYV4:LOAD-AGENTS") {
        const state = getState();
        const { preSetConnectorId, first, noPoll } = payload;
        const selectedOrgId = state.tenancy.selectedOrgId;

        const { data: { items: agentsV4 } } = await apiClient.get(`${externals.tenancyV4Backend}/organizations/${selectedOrgId}/users?filter=userType eq "agent"&${maxLimit}`);

        // Filter v4 agents which has ProjectId/FolderId tag
        const filteredV4Agents = _.filter(agentsV4, (agentV4) => {
            return (agentV4.tags.filter((tag) => tag["internal:bxp:projectId"] || tag["internal:bxp:folderId"] || tag["internal:bxp:organizationId"]))?.length > 0
        });

        // Group V4 agents by projectId, FolderId and OrganizationId as key
        const processedV4Agents = _.groupBy(filteredV4Agents, (agentV4) => {
            const projectId = _.get(_.find(agentV4.tags, (tag) => tag["internal:bxp:projectId"]), "internal:bxp:projectId");
            const folderId =  _.get(_.find(agentV4.tags, (tag) => tag["internal:bxp:folderId"]), "internal:bxp:folderId");
            const organizationId =  _.get(_.find(agentV4.tags, (tag) => tag["internal:bxp:organizationId"]), "internal:bxp:organizationId");
            return projectId ?? folderId ?? organizationId
        });

        dispatch({
            type: "TENANCY:LOAD-AGENTS",
            payload: {
                preSetConnectorId,
                first,
                noPoll,
                processedV4Agents
            }
        });
        
    } else if (type === "TENANCY:LOAD-ACCOUNTS") {
        // TODO to be cleaned up
        const tenancy = getState().tenancy;

        if (tenancy.accountStatus !== "PENDING") {
            return;
        }

        const search = querystring.parse(history.location.search);
        const preSetAccountId = tenancy.localAccountId || search.accountId;
        const preWorkspaceId = search.workspaceId;
        const preSetConnectorId = tenancy.localConnectorId || search.connectorId;

        if (search.accountId || search.workspaceId || search.connectorId) {
            delete search.accountId;
            delete search.workspaceId;
            delete search.connectorId;
            const searchString = querystring.stringify(search);

            history.replace(history.location.pathname + (searchString ? `?${searchString}` : ''));
        }

        try {
            const state = getState();
            const localStorageItemKey = { itemKey: "userAccountId", userId: state.auth.userId };
            trackTiming({ category: "APP", name: "started accounts" });
            const { data: accounts } = payload?.accounts ? { data: payload.accounts } : await apiClient.get(`${externals.tenancyBackend}/account`);
            const setupMode = state.app.appState === "setup-wizard";

            let selectedAccountId = fetchOrSetStoredItem({ storageItemKey: localStorageItemKey, collection: accounts, collectionItemKey: "accountPublicId", preSetItem: preSetAccountId });
            const forbidden = tenancy.localAccountId ? preSetAccountId !== selectedAccountId : false;

            if (forbidden) {
                selectedAccountId = null;
            }

            trackTiming({ category: "APP", name: "loaded accounts" });

            if(process.env.REACT_APP_NETAPP_ACCOUNT_AUTO_JOIN === "true" && state.auth.isNetAppInternal && accounts.length === 0 && matchPath(window.location.pathname,'/app-redirect/active-iq') && !sessionStorage.getItem("occm.activeiq.reload")) {
                try {
                    await apiClient.post(`${externals.portalBackend}/portal/occm-demo-signup`, null, {
                        params: {
                            autoJoin: true
                        }
                    })

                    sessionStorage.setItem("occm.activeiq.reload", "true")
                    await dispatch({
                        type: "APP/RELOAD"
                    })
                } catch (e) {
                    console.log(e);
                }
            }

            if(accounts.length === 0 && !sessionStorage.getItem("occm.activeiq.reload")) {
                try {
                    const { data : { invitationCount, hasJoinedNewAccounts } } = await apiClient.post(`${externals.tenancyBackend}/users/apply-invitation`)

                    if(invitationCount > 0 && hasJoinedNewAccounts) {
                        sessionStorage.setItem("occm.activeiq.reload", "true")
                        await dispatch({
                            type: "APP/RELOAD"
                        })
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            dispatch({
                type: "TENANCY:LOAD-ACCOUNTS-SUCCESS",
                payload: {
                    selectedAccountId,
                    forbidden,
                    accounts
                }
            });

            if (selectedAccountId && !setupMode) {
                setGaAccount(selectedAccountId);
                setFullStoryUserValues({ "AccountId": selectedAccountId });
                dispatch({
                    type: "TENANCY:LOAD-AGENTS",
                    payload: {
                        preSetConnectorId,
                        first: true
                    }
                });

                dispatch({
                    type: "TENANCY:LOAD-WORKSPACES",
                    payload: {
                        preWorkspaceId
                    }
                });
            } else {
                dispatch({
                    type: "TENANCY:NO-AGENTS",
                });
            }
        } catch (e) {
            dispatch({
                type: "TENANCY/LOAD-FAILED",
                payload: e
            })

            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    isFatal: true,
                    message: "Failed to load accounts",
                    exception: e
                }
            })
        }
        // end
    } else if (type === "TENANCY:LOAD-AGENTS") {
        const state = getState();
        const { preSetConnectorId, noPoll, processedV4Agents } = payload;
        const selectedAccountId = state.tenancy.selectedAccountId;
        const localStorageItemKey = { itemKey: "userConnectorId", userId: state.auth.userId };
        const firstTime = state.tenancy.agentStatus === "PENDING";
        const selectedScopeId = state.tenancy.selectedScopeId;
        const scopes = state.tenancy.scopes;
        let processedScopes = _.cloneDeep(scopes);

        const darkSiteAgent = () => {
            return {
                "data": {
                    "occms": [{
                        "account": state.app.about.siteIdentifier.company,
                        "accountName": state.app.about.siteIdentifier.company,
                        "occm": state.app.about.systemId,
                        "agentId": state.app.about.systemId,
                        "status": "ready",
                        "occmName": state.app.about.siteIdentifier.site,
                        "agent": {
                            "name": state.app.about.siteIdentifier.site,
                            "agentId": `${state.app.supportServices.portalService.auth0Information.clientId}clients`,
                            "provider": state.app.instancePlacement.installLocation,
                            "status": "active",
                            "region": state.app.instancePlacement.region,
                            "usingDockerInfra": true,
                            "clientInfraStatus": {
                                cloudmanager_cbs: {
                                    version: "cloudmanager_cbs:ds",
                                    reason: "",
                                    status: "active"
                                },
                                cloudmanager_compliance: {
                                    version: "cloudmanager_compliance:ds",
                                    reason: "",
                                    status: "active"
                                },
                                "cloudmanager_ontap-proxy": {
                                    version: "cloudmanager_ontap-proxy:ds",
                                    reason: "",
                                    status: "active"
                                }
                            }
                        }
                    }]
                }
            }
        };
        try {
            const { data: { occms: agents } } = process.env.REACT_APP_IS_DARK !== "true" ? await apiClient.get(`${externals.agentsBackend}/list-connectors/${selectedAccountId}`) : darkSiteAgent()

            let processedAgents = agents.map(occm => {
                const { agent, primaryCallbackUri } = occm;

                let region = _.get(agent, "region", "unknown");
                let provider = _.get(agent, "provider", "").toLowerCase();
                let status = _.get(agent, "status", "inactive");

                if (occm.status === "failed") {
                    status = "failed";
                }

                const name = _.get(agent, "name");
                const agentId = _.get(agent, "agentId");

                if (agent.provider === "gcp") {
                    const regionMatch = region.match(gcpRegionRegex);
                    if (regionMatch && regionMatch.length === 5) {
                        region = regionMatch[4];
                    }
                }

                if (provider === "onprem") {
                    provider = "on-prem";
                }

                return { ...occm, primaryCallbackUri, region, status, provider, name, agentId, id: agentId }
            });

            const activeAgents = _.filter(processedAgents, { status: "active" });
            processedAgents = _.sortBy(processedAgents, [(agent) => agent.status === 'active' ? -1 : agent.status === 'pending' ? 0 : 1], [agent => agent.name.toLowerCase()], 'name');
            // connectorChanges: Remove this comment once the agent association is approved. 
            // const collection = isTenancyV4 ? _.get(processedV4Agents, selectedScopeId, []) : (process.env.REACT_APP_IS_SAAS === "true" ? activeAgents : processedAgents); //We can allow connecting to offline connector in local mode
            const collection = process.env.REACT_APP_IS_SAAS === "true" ? activeAgents : processedAgents; //We can allow connecting to offline connector in local mode

            let preSetItem = preSetConnectorId;
            const state = getState();
            if (!firstTime) {
                preSetItem = state.tenancy.selectedAgentId;
            }
            // connectorChanges: Remove this comment once the agent association is approved. 
            // const selectedAgentId = fetchOrSetStoredItem({ storageItemKey: localStorageItemKey, collection, collectionItemKey: isTenancyV4 ? "auth0Id" : "agentId", preSetItem });
            const selectedAgentId = fetchOrSetStoredItem({ storageItemKey: localStorageItemKey, collection, collectionItemKey: "agentId", preSetItem });
            let isChangedAgent = false;
            let isTransitionFromUnselectedToSelected = false;
            if (!firstTime) {
                if (state.tenancy.selectedAgentId) {
                    isChangedAgent = state.tenancy.selectedAgentId !== selectedAgentId;
                } else {
                    isTransitionFromUnselectedToSelected = !!selectedAgentId;
                }
            } else {
                trackTiming({ category: "APP", name: "loaded agents" })
            }

            if (process.env.REACT_APP_IS_SAAS === "true") {
                setOccmAgent({ agentId: selectedAgentId, accountId: selectedAccountId });
            }

            setForwarderAgent({ agentId: selectedAgentId, accountId: selectedAccountId });

            if (isTenancyV4 && processedV4Agents) {
                // Recursive function to associate agents object inside each scope object which matches the scopeId with v4 agents projectId/FolderId/OrganizationId and list-connectors agents ID with auth0Id of v4 agents
                const recursiveProcessScope = (scopeValues) => {
                    return scopeValues.map((scope) => {
                        if (!_.isUndefined(scope.childScopes) && scope.childScopes.length > 0) {
                            scope.childScopes = recursiveProcessScope(scope.childScopes)
                        }
                        if (processedV4Agents[scope.id]) {
                            const scopeAgents = processedV4Agents[scope.id].map((agentsV4) => {
                                const index = _.findIndex(processedAgents, (agent) => agent?.agent?.agentId === agentsV4.auth0Id)
                                if (index > -1) {
                                    return processedAgents[index]
                                }
                            })
                            return {
                                ...scope,
                                associatedAgents: scopeAgents
                            }
                        }
                        return {
                            ...scope,
                            associatedAgents: []
                        }
                    })
                }

                if (scopes) {
                    processedScopes = recursiveProcessScope(scopes);
                }

                dispatch({
                    type: "TENANCYV4:LOAD-AGENTS-SUCCESS",
                    payload: {
                        scopes: processedScopes,
                        selectedScopeId
                    }
                });
            }

            dispatch({
                type: "TENANCY:LOAD-AGENTS-SUCCESS",
                payload: {
                    agents: processedAgents,
                    selectedAgentId,
                    isChangedAgent,
                    isTransitionFromUnselectedToSelected
                }
            });
            if (isTenancyV4) {
                const agentUserId = getItem({ itemKey: `userConnectorIdKey.${selectedAgentId}` });
                dispatch({
                    type: "TENANCY:SET-AGENT-USER",
                    payload: {
                        agentId: selectedAgentId,
                        agentUserId
                    }
                })
            }
            if (isChangedAgent && !selectedAgentId) {
                //Bug in angular, can't switch from having agent to not having agent, so refresh Iframe
                dispatch({
                    type: "APP:NG-DE-INIT"
                });
                setTimeout(() => {
                    dispatch({
                        type: "APP:NG-RE-INIT"
                    });
                }, 100);
            }

            if (isTransitionFromUnselectedToSelected) {
                setTimeout(() => {
                    dispatch({
                        type: "TENANCY:DISCARD-CHANGE-AGENT-MESSAGE"
                    })
                }, 8000)
            }
        } catch (e) {
            if (firstTime) {
                dispatch({
                    type: "TENANCY:LOAD-AGENTS-FAILED",
                    payload: e
                });
                dispatch({
                    type: "NOTIFICATIONS:ERROR",
                    payload: {
                        isFatal: true,
                        message: "Failed to load agent details",
                        exception: e
                    }
                });
            }
        } finally {
            if (!noPoll) {
                setTimeout(() => {
                    dispatch({
                        type: "TENANCY:LOAD-AGENTS",
                        payload: {
                            preSetConnectorId: null
                        }
                    })
                }, 30 * 1000);
            }
        }
    } else if (type === "TENANCY:SET-AGENT") {
        const { agentId } = payload;
        const state = getState();
        const userId = state.auth.userId;
        const accountId = state.tenancy.selectedAccountId;
    
        if (process.env.REACT_APP_IS_SAAS === "true") {
            setOccmAgent({ agentId, accountId });
        }
    
        setForwarderAgent({ agentId, accountId });
    
        setItem({ itemKey: "userConnectorId", userId }, agentId);
        if (isTenancyV4) {
            dispatch({
                type: "TENANCY:SET-AGENT-USER",
                payload: {
                    agentId: agentId
                }
            })
        }
    } else if (type === "TENANCY:SET-AGENT-USER") {
        const { agentId, agentUserId } = payload;
        const state = getState();
        const userAgentMap = state.tenancy.userAgentMap;
        const selectedOrgId = state.tenancy.selectedOrgId;
        if (!agentId) {
            dispatch({
                type: "TENANCY:SET-AGENT-USER-SUCCESS",
                payload: { agentUserId,  agentId}
            })
        } else if (agentUserId) {
            dispatch({
                type: "TENANCY:SET-AGENT-USER-SUCCESS",
                payload: { agentUserId, agentId }
            })
        } else if (userAgentMap && userAgentMap[agentId]) {
            dispatch({
                type: "TENANCY:SET-AGENT-USER-SUCCESS",
                payload: { agentUserId: userAgentMap[agentId] }
            })
            setItem({ itemKey: `userConnectorIdKey.${agentId}` }, userAgentMap[agentId]);
        } else {
            try {
                const { data: { items: users } } = await apiClient.get(`${externals.tenancyV4Backend}/organizations/${selectedOrgId}/users?filter=auth0Id eq '${agentId}' and userType eq 'agent'`);
                dispatch({
                    type: "TENANCY:SET-AGENT-USER-SUCCESS",
                    payload: { agentId, agentUserId: users[0].id }
                })
                setItem({ itemKey: `userConnectorIdKey.${agentId}` }, users[0].id);
            }  catch (e) {
                dispatch({
                    type: "TENANCY:SET-AGENT-USER-FAILED",
                    payload: e
                })
    
                dispatch({
                    type: "NOTIFICATIONS:ERROR",
                    payload: {
                        isFatal: true,
                        message: "Failed to load agent details",
                        exception: e
                    }
                })
            }
        }
    }
    // TODO to be removed
    else if (type === "TENANCY:LOAD-WORKSPACES") {
        
        const state = getState();
        const { preWorkspaceId } = payload;
        const selectedAccountId = state.tenancy.selectedAccountId;
        const localStorageItemKey = { itemKey: "userWorkspaceId", userId: state.auth.userId };

        try {
            const { data: workspaces } = await apiClient.get(`${externals.tenancyBackend}/account/${selectedAccountId}/workspace`)

            const selectedWorkspaceId = fetchOrSetStoredItem({ storageItemKey: localStorageItemKey, collection: workspaces, collectionItemKey: "workspacePublicId", preSetItem: preWorkspaceId });

            dispatch({
                type: "TENANCY:LOAD-WORKSPACES-SUCCESS",
                payload: {
                    workspaces,
                    selectedWorkspaceId
                }
            })
        } catch (e) {
            dispatch({
                type: "TENANCY:LOAD-WORKSPACES-FAILED",
                payload: e
            })

            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    isFatal: true,
                    message: "Failed to load workspaces",
                    exception: e
                }
            })
        }
    } else if (type === "TENANCY:SET-ACCOUNT") {
        const { accountId } = payload;
        const userId = getState().auth.userId;

        setItem({ itemKey: "userAccountId", userId }, accountId);
        if (window.location.pathname.startsWith("/storage")) {
            window.location.replace("/");
        } else {
            window.location.reload();
        }
    } else if (type === "TENANCY/SET-PROJECT") {
        const { projectId, organizationId } = payload;
        const userId = getState().auth.userId;

        setItem({ itemKey: "userTenancy", userId }, {
            lastProjectId: projectId,
            lastOrganizationId: organizationId
        })
        window.location.reload();
    } else if (type === "TENANCY:SET-WORKSPACE") {
        const { workspaceId } = payload;
        const userId = getState().auth.userId;

        setItem({ itemKey: "userWorkspaceId", userId }, workspaceId);
    } else if (type === "TENANCY:CREATE-ACCOUNT") {
        const { accountName } = payload;
        try {
            dispatch({
                type: "TENANCY:CREATE-ACCOUNT-PENDING"
            });
            const account = await apiClient.post(`${externals.tenancyBackend}/account/${accountName}`, {});
            const accountId = _.get(account, 'data.accountPublicId');
            dispatch({
                type: 'TENANCY:CREATE-ACCOUNT-SUCCESS',
                payload: { accountId }
            });
            dispatch({
                type: "TENANCY:SET-ACCOUNT",
                payload: { accountId }
            })
        } catch (e) {
            dispatch({
                type: "TENANCY:CREATE-ACCOUNT-FAILED",
                payload: e
            })
        }

    } else if (type === "TENANCY/CREATE-ORGANIZATION") {
        const { organizationName } = payload;

        return handleApiAction(action, () => {
            return apiClient.post(`${externals.platformBackend}/v1/NetApp.Tenancy/organizations`, {
                name: organizationName
            });
        }).then(() => {
            window.location.reload();
        }).catch(e => {
            dispatch({
                type: "NOTIFICATIONS:ERROR",
                payload: {
                    message: "Failed to create organization",
                    exception: e,
                    group: "TENANCY/CREATE-ORGANIZATION"
                }
            })
        })

    } else if (type === "TENANCY:LOAD-ACCOUNTS-SUCCESS") {
        const search = querystring.parse(window.location.search);

        if (search?.redirectFrom) {
            setTimeout(() => {
                const selectedAccountId = getState().tenancy.selectedAccountId;

                if (selectedAccountId) {
                    const search = querystring.parse(window.location.search);

                    if (search.redirectFrom === "spot") {
                        if (!search.spotOrgId) {
                            console.warn("Redirect from spot, but missing spotOrgId");
                        } else {
                            const spotOrgId = search.spotOrgId;

                            import('modules/spot' /* webpackChunkName: "spot"*/).then(() => {
                                dispatch({
                                    type: "SPOT:ASSOCIATE-EXTERNAL-ORG",
                                    payload: {
                                        spotOrgId: spotOrgId,
                                        selectedAccountId
                                    }
                                })
                            })
                        }

                        delete search.spotOrgId;
                        delete search.redirectFrom;

                        const searchString = querystring.stringify(search);

                        history.replace(window.location.pathname + (searchString ? `?${searchString}` : ''));
                    }
                }
            });
        }
    }
    // end

    return next(action);
}
